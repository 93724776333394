








































































import useAttachment from "@/use/attachment";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import copy from "copy-to-clipboard";

export default defineComponent({
  components: {
    OAttachments: () => import("@/components/organisms/o-attachments.vue"),
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  setup(props, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const {
      fileTypesString,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    } = useAttachment({ root });

    const attachments = ref<any[]>([]);
    const form = ref<any>(null);

    const state = reactive({
      data: {
        headers: [
          { text: "Pole", value: "field" },
          { text: "Wartość", value: "value" },
          { text: "", value: "action", align: "end" },
        ],
        items: computed(() =>
          props && props.details
            ? [
                {
                  field: "A. Aktualny numer rejestracyjny pojazdu",
                  value:
                    (props.details.registrationNumber &&
                      props.details.registrationNumber.toUpperCase()) ||
                    "---",
                },
                {
                  field: "B. Data pierwszej rejestracji pojazdu",
                  value: props.details.firstRegistrationDate || "---",
                },
                {
                  field:
                    "C.1.1. Nazwisko lub nazwa posiadacza dowodu rejestracyjnego",
                  value:
                    props.details.registrationCertificateHolderName || "---",
                },
                {
                  field: "C.1.2. Numer PESEL lub REGON",
                  value:
                    props.details.registrationCertificateHolderNumber || "---",
                },
                {
                  field: "C.1.3. Adres posiadacza dokumentu",
                  value:
                    props.details.registrationCertificateHolderAddress || "---",
                },
                {
                  field: "C.2.1. Nazwisko lub nazwa właściciela pojazdu",
                  value:
                    props.details.registrationCertificateOwnerName || "---",
                },
                {
                  field: "C.2.2. Numer PESEL lub REGON",
                  value:
                    props.details.registrationCertificateOwnerNumber || "---",
                },
                {
                  field: "C.2.3. Adres właściciela pojazdu",
                  value:
                    props.details.registrationCertificateOwnerAddress || "---",
                },
                {
                  field: "D.1. Marka pojazdu",
                  value: props.details.vehicleBrand || "---",
                },
                {
                  field: "D.2. Typ pojazdu (wraz z wariantem i wersją)",
                  value: props.details.vehicleType || "---",
                },
                {
                  field: "D.3. Model pojazdu",
                  value: props.details.vehicleModel || "---",
                },
                {
                  field:
                    "E. Numer identyfikacyjny pojazdu (numer VIN lub numer nadwozia, podwozia albo ramy)",
                  value: props.details.vinNumber || "---",
                },
                {
                  field:
                    "F.1. Maksymalna masa całkowita pojazdu, nie dotyczy motocykli i motorowerów [kg]",
                  value: `${props.details.maximumGrossVehicleWeight ||
                    "---"} kg`,
                },
                {
                  field: "F.2. Dopuszczalna masa całkowita pojazdu [kg]",
                  value: `${props.details.grossVehicleWeight || "---"} kg`,
                },
                {
                  field:
                    "F.3. Dopuszczalna masa całkowita zespołu pojazdów [kg]",
                  value: `${props.details.grossVehicleCombinationWeight ||
                    "---"} kg`,
                },
                {
                  field: "G. Masa własna pojazdu",
                  value: `${props.details.vehicleCurbWeight || "---"} kg`,
                },
                {
                  field: "H. Okres ważności dowodu (jeśli dotyczy)",
                  value:
                    props.details.registrationCertificateValidityPeriod ||
                    "---",
                },
                {
                  field: "I. Data wydania dowodu rejestracyjnego",
                  value:
                    props.details.registrationCertificateIssueDate || "---",
                },
                {
                  field: "J. Kategoria pojazdu",
                  value: props.details.vehicleCategory || "---",
                },
                {
                  field:
                    "K. Numer świadectwa homologacji typu pojazdu (jeśli występuje)",
                  value: props.details.approvalCertificateNumber || "---",
                },
                {
                  field: "L. Liczba osi",
                  value: props.details.axlesNumber || "---",
                },
                {
                  field:
                    "O.1. Maksymalna masa całkowita przyczepy z hamulcem [kg]",
                  value: `${props.details.maximumGrossTrailerWeightWithBrakes ||
                    "---"} kg`,
                },
                {
                  field:
                    "O.2. Maksymalna masa całkowita przyczepy bez hamulca [kg]",
                  value: `${props.details
                    .maximumGrossTrailerWeightWithoutBrakes || "---"} kg`,
                },
                {
                  field: "P.1. Pojemność silnika [cm³]",
                  value: `${props.details.engineCapacity || "---"} cm³`,
                },
                {
                  field: "P.2. Maksymalna moc netto silnika [kW]",
                  value: `${props.details.maximumEnginePower || "---"} kW`,
                },
                {
                  field: "P.3. Rodzaj paliwa",
                  value: props.details.fuelType || "---",
                },
                {
                  field:
                    "Q. Stosunek mocy do masy własnej (dotyczy motocykli i motorowerów) [kW/kg]",
                  value: props.details.powerToWeightRatio || "---",
                },
                {
                  field:
                    "S.1. Liczba miejsc siedzących, włączając siedzenie kierowcy",
                  value: props.details.seatsNumber || "---",
                },
                {
                  field: "S.2. Liczba miejsc stojących (jeśli występuje)",
                  value: props.details.standingPlacesNumber || "---",
                },
                {
                  field: "Rodzaj pojazdu",
                  value: props.details.vehicleKind || "---",
                },
                { field: "Przeznaczenie", value: "---" },
                {
                  field: "Rok produkcji",
                  value: props.details.productionYear || "---",
                },
                {
                  field: "Dopuszczalna ładowność",
                  value: props.details.loadCapacity || "---",
                },
                {
                  field: "Największy dopuszczalny nacisk osi",
                  value: `${props.details.maximumAxleLoad || "---"} kN`,
                },
                {
                  field: "Numer karty pojazdu",
                  value: props.details.vehicleCardNumber || "---",
                },
              ]
            : []
        ),
      },

      attachments: {
        dialog: false,
        isFormValid: true,
        loading: false,
        total: 0,
        options: {
          page: 1,
          itemsPerPage: 10,
        },
      },
    });

    const model = reactive({
      data: { search: "" },
      attachments: [],
    });

    const rules = {
      attachments: [
        (v: File[]) => (!!v && !!v.length) || "Dodaj co najmniej jeden plik.",
        (v: File[]) =>
          !v || (v && v.length <= 5) || "Maksymalna ilość plików na raz to 5.",
      ],
    };

    const resetModel = () => {
      model.attachments = [];

      form.value.resetValidation();
    };

    const fetchAttachments = () => {
      const { page, itemsPerPage } = state.attachments.options as DataOptions;

      state.attachments.loading = true;

      axiosInstance
        .get(`vehicle/${props.id}/details/attachment`, {
          params: { page, itemsPerPage },
        })
        .then(({ data }) => {
          attachments.value = data.attachments;
          state.attachments.total = data.total;
        })
        .catch(() => {
          attachments.value = [];
          state.attachments.total = 0;
        })
        .finally(() => (state.attachments.loading = false));
    };

    const addAttachemnts = async () => {
      const attachmentData = {
        attachment: [],
      };

      if (model.attachments && model.attachments.length) {
        state.attachments.loading = true;

        await uploadAttachments(model.attachments)
          .then(({ data }) => {
            attachmentData.attachment = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch((error) => console.log(error));

        axiosInstance
          .post(`vehicle/${props.id}/details/attachment`, attachmentData)
          .then(() => {
            state.attachments.dialog = false;
            resetModel();
            fetchAttachments();
          })
          .catch(console.log)
          .finally(() => (state.attachments.loading = false));
      }
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.attachments.isFormValid) addAttachemnts();
    };

    watch(() => state.attachments.options, fetchAttachments, { deep: true });
    onMounted(fetchAttachments);

    const copySnackbar = ref(false);
    const copySnackbarText = ref("");

    const copyToClipboard = (text: string) => {
      copy(text, {
        format: "text/plain",
        onCopy: () => {
          copySnackbar.value = false;
          nextTick(() => {
            copySnackbarText.value = text;
            nextTick(() => {
              copySnackbar.value = true;
            });
          });
        },
      });
    };

    return {
      attachments,
      fileTypesString,
      downloadAttachment,
      deleteAttachment,
      form,
      state,
      model,
      rules,
      fetchAttachments,
      onSubmit,
      copyToClipboard,
      copySnackbar,
      copySnackbarText,
    };
  },
});
